.footer {
   font-size: $txt-footer;
   color: $text-color-light;

   a {
      display: block;
      margin-bottom: 5px;
      cursor: pointer;

      &:hover {
         text-decoration: underline;
      }

      &.privacy {
         margin: 0;
      }
   }

   
   &-nav {
      &-title {
         font-weight: bold;
         margin-bottom: 10px!important;
         letter-spacing: 0.8px;
      }
   
      @include mobile {
         &-title {
            margin-bottom: 0!important;
         }
         &-item {
            border-bottom: 1px solid #d9d9d9;
            padding: 18px 0;
         }

         &-subitem {
            padding: 18px 0 0;
         }

         &-icon {
            background-repeat: no-repeat;
            background-position: right 16px;
            background-image: url(../images/assets/ic-footer-expanded.svg);

            &.collapsed {
               background-image: url(../images/assets/ic-footer-collapsed.svg);
            }
         }
         
         &-submenu {
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.3s ease-in-out;
            &.expanded {
               max-height: 600px;
            }
         }
      }
      
   }
   
   
   
   &-copyrights {
      margin: 20px 0 50px 0;
      color: #000000;
      font-size: $txt-xs;

      @include mobile {
         text-align: center;
         justify-content: center;

         .no-mobile-splitter::after {
            display: none;
         }
      }
   }

   .splitter-rounded {
      padding-right: 20px;
      display: block;

      &::after {
         right: 10px;
      }

      @include mobile {
         margin-bottom: 10px;
      }
   }

   .social {
      a {
         margin: 0 14px 10px 0;

         &:last-child {
            margin-right: 0;
         }
      }

      .social-title {
         margin: 60px 0 15px 0;

         @include mobile {
            text-align: center;
            margin: 35px 0 15px 0;
         }
         
      }
   }

  
   .margin-top-lg-down {
      @include phablet {
         margin-top: 50px;
      }

      @include mobile {
         margin-top: 0;
      }
   }
}