.devices-list {
  margin-top: 60px;
  .img-wrapper {
    border: 1px solid #d9d9d9;
    padding: 15px 75px;
    margin-bottom: 5px;
  }
  .ref {
    color: #4d4d4d;
    font-size: 1.2rem;
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: $text-color;
    display: block;
    margin-bottom: 10px;
  }
  .price {
    font-weight: 400;
    letter-spacing: 0.64px;
    display: block;
  }
  .link-underline {
    display: inline-block;
    margin-bottom: 50px;
  }
}

.device-detail {
  @include phablet {
    margin-top: 45px;
    [class*="col-"] {
      margin-bottom: 20px;
    }
  }

  .gallery-top {
    height: 500px;
    width: 100%;
    box-sizing: border-box;
    @include mobile {
      height: auto;
    }
  }
  .gallery-thumbs {
    //height: 55px;
    box-sizing: border-box;
    padding: 10px 0;
  }
  .swiper-slide {
    box-sizing: border-box;
  }

  .device-slide {
    border: 1px solid #d9d9d9;
    img {
      margin: auto;
      height: 100%;
      @include mobile {
        width: 100%;
        height: auto;
      }
    }
  }

  .lSPager {
    li {
      border: 1px solid #d9d9d9;
      //height: 55px;
      width: 100%;
      max-width: 75px;
      border-radius: unset;
      &.active {
        border: 1px solid #000;
        border-radius: unset !important;
      }
      img {
        margin: auto;
        height: 100%;
        @include small-mobile {
          width: 80%;
          height: auto;
        }
      }
    }
  }

  .ref {
    color: #4d4d4d;
    font-size: 1.4rem;
    margin-bottom: 25px;
  }
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: $text-color;
    display: block;
    margin-bottom: 10px;
    letter-spacing: 0.8px;
  }
  .sub-title {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.64px;
    margin-bottom: 70px;
    display: block;
  }
  .buy-info {
    margin-bottom: 45px;
    padding-bottom: 30px;
    position: relative;
    &::after {
      content: "";
      width: calc(100% - 30px);
      height: 1px;
      background: #d9d9d9;
      bottom: 0;
      left: 15px;
      position: absolute;
    }
  }

  .price {
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 1.2px;
  }
  .discription {
    font-size: 1.3rem;
    display: block;
    margin-bottom: 50px;
  }
  .social {
    p {
      font-size: 1.3rem;
      letter-spacing: 0.6px;
    }
  }
}

.device-table {
  margin-bottom: 95px;

  .title-wrapper {
    margin-bottom: 30px;

    .title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 0.8px;
      margin-bottom: 5px;
    }

    .lead {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }

  .col-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &::before {
      content: "";
      width: calc(100% - 30px);
      margin-left: 15px;
      height: 1px;
      background-color: #d9d9d9;
    }

    .line-item {
      font-size: 0;
      border-bottom: 1px solid #d9d9d9;
      padding: 15px 0;
      margin: 0 15px;
      flex-grow: 1;
      flex: 1 0 calc(33% - 30px);
      @include tablet {
        flex: 1 0 calc(50% - 30px);
      }

      @include mobile {
        flex: 1 0 calc(100% - 30px);
      }
    }
  }

  .left {
    width: 50%;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .right {
    width: 50%;
    text-align: right;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 400;
  }
}
