a.skip {
    @include sr-only;
    @include sr-only-focusable;

    &:active,
    &:focus {
        position: fixed;
        padding: 10px;
        border: 2px solid white;
        z-index: 99;
        top: 15px;
        left: 15px;
        background: black;
        text-decoration: none;
        color: white;
    }
}
