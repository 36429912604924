.main-menu {
  max-width: 50%;
  width: 70vw;
  position: fixed;
  top: 0;
  right: -100vw;
  bottom: 0;
  padding: 55px 30px;
  z-index: $zindex-xl;
  background: white;
  overflow-y: scroll;
  transition: right 1s;
  &.open {
    right: 0;
    
  }

 

  &::after {
    transition: ease-in-out 1s;
    opacity: 0;
  }
  .close-wrapper {
    text-align: right;
    margin-bottom: 30px;
    .close {
      display: inline-block;
      img {
        transition: transform 0.8s ease-in-out,
          -webkit-transform 0.8s ease-in-out;
        &:hover {
          transform: rotate(90deg);
        }
      }
    }
  }

  @include phablet {
    max-width: unset;
  }
  @include mobile {
    width: 100vw;
  }

  .search-wrapper {
    margin-bottom: 30px;
    position: relative;
    :focus {
      outline: none;
    }

    .search {
      border: 1px solid #d9d9d9;
      width: 100%;
      max-width: 520px;
      font-weight: 400;
      background: transparent;
      border-radius: 23px;
      padding: 10px 60px 10px 30px;
      color: currentColor;
    }
    .search-btn {
      font-size: 0;
      width: 19px;
      height: 19px;
      background: url(../images/assets/ic-search.svg) no-repeat;
      position: absolute;
      top: 13px;
      right: 20px;
    }
  }

  .menu-header {
    max-width: 495px;
    @include mobile {
      max-width: unset;
    }
  }

  .menu-items {
    max-width: 495px;
    @include mobile {
      max-width: unset;
    }

    .menu-item {
      color: $text-color-light;
      font-size: 0;
      font-weight: 500;
      border-bottom: 1px solid #d9d9d9;
      padding: 17px 20px;
      text-transform: uppercase;
      a {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 15px);
        font-size: 1.2rem;
      }
    }

    .submenu-item {
      font-weight: 400;
      padding: 20px 20px 0 20px;
      text-transform: none;
    }
    .toggle-submenu {
      background: url(../images/assets/ic-arrow.svg) no-repeat;
      background-size: 14px 9px;
      width: 14px;
      height: 9px;
      transform: rotate(180deg);
      display: inline-block;
      vertical-align: middle;
    }

    .sub-menu {
      max-width: 50%;
      width: 70vw;
      position: fixed;
      top: 75px;
      right: -100vw;
      -webkit-transition: right 0.5s;
      transition: right 0.5s;
      bottom: 0;
      padding: 20px 30px;
      z-index: 40;
      background: white;
      font-size: 1.2rem;
      &.open {
        right: 0;
      }
      @include phablet {
        max-width: unset;
        top: 151px;
      }
      @include mobile {
        width: 100vw;
      }
    }
    .go-back {
      font-weight: 500;
      border-bottom: 1px solid #d9d9d9;
      padding: 17px 20px;
      text-transform: uppercase;
      max-width: 495px;
      @include mobile {
        max-width: unset;
      }

      &::before {
        content: "";
        background: url(../images/assets/ic-arrow.svg) no-repeat;
        background-size: 14px 9px;
        width: 14px;
        height: 9px;
        margin-right: 15px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
