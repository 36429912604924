.main-header {
  position: relative;
  z-index: $zindex-lg;
  top: 0;
  height: auto;
  width: 100%;
  color: black;
  background: white;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 75px;

  .homepage & {
    position: absolute;
    background: transparent;
    border-bottom: 0;
    margin-bottom: 0;

    color: white;
    .logo {
      &::before {
        display: none;
      }
    }
  }

  .logo {
    padding: 30px 0;
    display: block;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 1000%;
      top: 0;
      height: 100%;
      right: 50px;
      background-color: $brand-blue;
      z-index: -1;
      @include phablet {
        right: 0;
      }
    }
    @include mobile {
      img {
        width: 60px;
      }
    }
  }

  .search-wrapper {
    position: relative;
    color: #dddddd;
    .homepage & {
      color: white;
    }
    :focus {
      outline: none;
    }
    .search {
      border: 0;
      border: 1px solid #dddddd;
      width: 100%;
      max-width: 520px;
      font-weight: 400;
      background: transparent;
      border-radius: 23px;
      padding: 10px 60px 10px 30px;
      color: #7d7d7d;

      &::placeholder {
        color: #7d7d7d;
        .homepage & {
          color: currentColor;
        }

        opacity: 1;
      }
    }
    .search-btn {
      font-size: 0;
      width: 19px;
      height: 19px;
      background: url(../images/assets/ic-search-grey.svg) no-repeat;
      .homepage & {
        background: url(../images/assets/ic-search-white.svg) no-repeat;
      }
      position: absolute;
      top: 13px;
      right: 20px;
    }
  }
  .header-links {
    li {
      display: inline-block;
      vertical-align: middle;
    }
    a {
      display: inline-block;
      vertical-align: middle;
      &::after {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .private-area {
    a {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 22px;
      &::after {
        content: url(../images/assets/ic-personal-area-black.svg);
        .homepage & {
          content: url(../images/assets/ic-personal-area.svg);
        }
        width: 19px;
        height: 22px;
        margin-left: 5px;
      }
      @include mobile {
        font-size: 0;
      }
    }
  }
  .cart {
    margin-left: 15px;
    a {
      font-size: 0;
      width: 20px;
      height: 25px;
      &::after {
        content: url(../images/assets/ic-cart-black.svg);
        .homepage & {
          content: url(../images/assets/ic-cart.svg);
        }
        width: 19px;
        height: 24px;
      }
    }

    .nmbr-items {
      font-size: 1.2rem;
      font-weight: 400;
      vertical-align: middle;
    }
  }
  .open-menu {
    margin-left: 15px;
    a {
      font-size: 0;
      &::after {
        content: url(../images/assets/ic-menu-black.svg);
        .homepage & {
          content: url(../images/assets/ic-menu.svg);
        }
        width: 23px;
        height: 15px;
        margin-left: 15px;
      }
    }
  }
}
