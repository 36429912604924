input, select {
   -webkit-appearance: none;
   -moz-appearance:none;
   appearance: none;
 }
 
 select {
   background: url(../images/assets/arrow-select.svg) no-repeat right 15px center;
   padding: 10px 30px 10px 15px;
 }
 
 .ipt, .select {
   height: 60px;
   border: 1px solid #d9d9d9;
   padding: 15px 30px;
   font-size: 1.5rem;
   line-height: 38px;
   margin: 0;
 
   &.full {
     width: 100%;
   }
 
   &.small {
     padding: 8px;
     height: 40px;
     width: 30px;
     text-align: center;
     font-size: 1.3rem;
     line-height: 40px;
   }
 }
 
 .select {
   padding: 10px 30px 10px 15px;
 }
 
 .form-complaint {
   margin-bottom: 80px;
 
   @include md {
     margin-bottom: 160px;
   }
 
   @include lg {
     margin-bottom: 240px;
   }
 
   input {
     margin-bottom: 19px;
   }
 }
 
 .fieldset {
   margin-bottom: 65px;
 
   &.margin-top {
     margin-top: 65px;
   }
 }
 
 .form-legend {
   margin-bottom: 30px;
   max-width: 270px;
 
   @include xs {
     max-width: none;
   }
 
   @include lg {
     
   }
 
   &-title {
     font-size: 2rem;
     font-weight: 600;
     letter-spacing: 0.8px;
     display: block;
   }
   &-lead {
     font-size: 1.3rem;
     line-height: 20px;
     margin-bottom: 15px;
     display: block;
 
     @include xs {
       margin-bottom: 0;
     }
   }
 }
 
 .form-label-lg {
   font-size: 1.6rem;
   letter-spacing: 0.64px;
   line-height: 26px;
   margin-bottom: 20px;
 
   @include md {
     margin-bottom: 10px;
   }
 
   &.margin-top {
     margin-top: 45px;
   }
 }
 
 .radio-wrapper,
 .checkbox-wrapper {
   position: relative;
   cursor: pointer;
   font-size: 15px;
   display: block;
 
   &.margin-bottom {
     margin-bottom: 20px;
   }
 }
 
 [type="radio"],
 [type="checkbox"] {
     position: absolute;
     left: 0;
     cursor: pointer;
     top:0;
     width: 24px;
     height: 24px;
     z-index: 1;
     margin: 0!important;
     
     + .label {
       position: relative;
       padding-left: 35px;
       cursor: pointer;
       line-height: 24px;
       display: inline-block;
 
       &::before,
       &::after {
         content: '';
         position: absolute;
       }
 
       &:before {
         left: 0;
         top: 0;
         width: 24px;
         height: 24px;
         border: 1px solid #d9d9d9;
         background: #fff;
       }
 
       &:after {
         -webkit-transition: all 0.2s ease;
         transition: all 0.2s ease;
       }
     }
 
 
   &:not(:checked) + .label:after {
       opacity: 0;
       transform: scale(0);
   }
 
   &:checked + .label:after {
       opacity: 1;
       transform: scale(1);
   }
 }
 
 [type="radio"] {
   + .label {
     &::before,
     &::after {
       border-radius: 100%;
     }
 
     &:after {
       width: 14px;
       height: 14px;
       background: #3840e6;
       border: 1px solid #3840e6;
       top: 5px;
       left: 5px;
     }
   }
 }
 
 [type="checkbox"] {
   + .label {
     &:after {
       width: 16px;
       height: 14px;
       background-image: url('../images/assets/checkbox-checked.svg');
       background-size: contain;
       background-repeat: no-repeat;
 
       
       top: 5px;
       left: 4px;
     }
   }
 }
 
 .datepicker {
   background: url(../images/assets/datepicker.svg) no-repeat right 25px center;
   padding-right: 70px;
 }
 
 .textarea {
   border: 1px solid #d9d9d9;
   padding: 15px 30px;
   margin-bottom: 45px;
 }
 
 
 [type="reset"],
 .reset {
   background:none;
   border: none;
   cursor: pointer;
   text-decoration: underline;
   line-height: 20px;
   font-size: 14px;
 
   @include md {
     letter-spacing: 0.6px;
   }
 
   &:hover {
     text-decoration: none;
   }
 
 }
 