.cleanstyle {
  h1,h2,h3,h4,h5,h6 {
    text-align: left;
    margin-left:0;
    margin-right:0;
  }
    line-height: 1.6;
    font-size: $font-size-base;
  
    img {
        max-width: 100%;
        display: inline-block;
        margin: 40px 0;
    }

  p {
    margin-bottom: 35px;
  }
  
}
  