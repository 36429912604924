.social {
  a {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    margin: 0 15px 0 0;
    &:hover {
      opacity: 0.5;
    }
  }

  img {
    width: 17px;
    height: 17px;

    &.gplus {
      width: 28px;
    }
  }

  @include mobile {
    .social-align {
      justify-content: center;
    }
  }
  
}