.last-news {
    
    .container{
        overflow-x: hidden;
    }
  .title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 30px;
    display: inline-block;
    &::before {
      margin: 0 15px 0 0;
    }
  }

  .item {
    .pre-title {
      font-size: 1.3rem;
      color: $text-color-light;
      font-weight: 400;
      display: inline-block;
      margin-bottom: 15px;
    }
    .title {
      font-weight: 600;
      margin-bottom: 30px;
    }
    .txt {
      margin-bottom: 25px;
    }
  }
  .bottom {
    margin-top: 25px;
  }
}

.news-date {
  color: $text-color-light;
  font-size: $txt-xs;
  margin: 35px 0 25px 0;

  @include mobile {
    margin: 15px 0 25px 0;
  }
}

.news-list {
  margin-top: 60px;

  @include mobile {
    margin-top: 40px;
  }

  .news-date {
    margin: 0 0 15px 0;
  }
  .title {
    margin-bottom: 25px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 1.5;
  }
  .description {
    margin-bottom: 25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.banner-news-detail,
.banner-news {
  margin-top: 45px;
}

@include lg {
  .banner-news-detail,
  .banner-news {
    margin-top: 0;
  }
}

.banner-news {
  margin-bottom: 30px;

  @include lg {
    margin-bottom: 60px;
  }

  &-img {
    width: 100%;
    object-fit: cover;
    height: 670px;
    object-position: top;
  }
}

.bg-banner-80 {
  background-color: rgba(56, 64, 230, 0.8);
  height: 100%;
  color: #ffffff;
  line-height: 26px;
  padding: 60px;

  @include mobile {
    padding: 20px;
  }
  .news-date {
    color: #FFFFFF;
    margin: 0 0 15px 0;
  }
  .title {
    margin-bottom: 10px;
    max-width: 390px;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 1.5;

    @include xs {
      margin-bottom: 30px;
    }
  }

  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 25px;
  }

}

.banner-caption {
  position: absolute;
  top:0;
  bottom: 0;

  @include phablet {
    top: auto;
    bottom: 0;
  }
}
.news-order {
    display: flex;
    justify-content: flex-end;
    align-items: center;

  .news-count {
    color: #4d4d4d;
    font-size: 13px;
    line-height: 20px;
    margin-right: 15px;

    @include xs {
      margin-right: 20px;
    }
  }
  .select {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 40px;
    line-height: 1.4rem;
    color: #4d4d4d;
  }
}

.news-pagination {
  font-size: 13px;
  line-height: 40px;
  color: #4d4d4d;
  height: 40px;
  margin: 0 0 70px 0;

  @include lg {
    margin: 65px 0 110px 0;
  }

  .btn-prev,
  .btn-next {
    background-image: url(../images/assets/arrow-pagination.svg);
    background-repeat: no-repeat;
    background-position: center center;
    height: 40px;
    width: 40px;
    margin: 0 30px;
    
    &:hover {
      opacity: 0.6;
    }
  }

  .btn-next {
    transform: rotate(180deg);
  }

  .total-pages {
    margin-left: 10px;
  }
}