/**
* IN THIS FLIE YOU CAN FIND:
*
* All the GENERIC selectors for styling IMAGES
*
* --------------------------
*
* .img-fluid                        make image fill 100% of available space
* .img-full-height                  make image fill 100% height and center horizontally desktop and mobile
*
* --------------------------
*
*/

.img-fluid {
  width: 100%;
  height: auto;
}

.img-full-height {
    left: 50%;
    transform: translateX(-50%);
}