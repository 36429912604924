html,
body {
  margin: 0;
  border: none;
  padding: 0;
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 62.5%;
  /*
  @include tablet {
    font-size: 60%;
  }
  @include mobile {
    font-size: 55%;
  }
  */
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $text-color;
  line-height: $line-height-base;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  line-height: $line-height-base;

}

strong,
b {
  font-weight: 700;
}

small {
  font-size: 80%;
}

img,
figure {
  display: block;
  border: none;
}

figure {
  margin: 0;
  width: 100%;
  padding: 0;
}

fieldset {
  margin: 0;
  border: none;
  padding: 0;
}

legend {
  margin: 0;
  border: none;
  padding: 0;
  width: 100%;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

textarea {
  border: none;
  resize: none;
  width: 100%;
}

iframe {
  border: none;
}

ul,
dl,
dd,
ol {
  margin: 0;
  list-style: none;
  padding: 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

p {
  margin: 0;
}

[disabled],
.disabled:not(.col) {
  pointer-events: none;
  background: grey;
  border-color: grey;
  cursor: default;
}

.hidden,
[hidden] {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  margin: -1px;
  border: none;
  padding: 0;
  width: 1px;
  height: 1px;
}
