.last-events {
  background: url(../images/assets/bg-repeater-grey.svg) repeat-x;
  padding: 40px 0;
  .container {
    overflow-x: hidden;
  }
  .title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 30px;
    display: inline-block;
    &::before {
      margin: 0 15px 0 0;
    }
  }

  .header-block {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
  }

  .item {
    background: white;
    padding: 30px 20px 40px;
    box-sizing: border-box;
  }

  .date {
    display: block;
    &.begin {
      font-size: 4rem;
      font-weight: 700;
    }
    &.end {
      font-weight: 500;
      font-size: 2rem;
    }
  }
  .txt {
    margin-bottom: 20px;
    font-size: 1.3rem;
    font-weight: 500;
  }
  .bottom {
    margin-top: 25px;
  }
}
