.splitter {

  &-footer {
    &-top {
       height: 1px;
       background-color: #d9d9d9;
       margin: 80px 0;

      @include phablet {
          margin: 35px 0;
      }

      @include mobile {
        margin: 35px 0 0;
      }
    }
    
    &-bottom {
       height: 1px;
       background-color: #d9d9d9;
       margin: 80px 0 0;

       @include phablet {
        margin: 35px 0 0;
      }
    }

    
  }

  &-news {
    width: 142px;
    height: 1px;
    background-color: #d9d9d9;
    margin: 30px 0;

    &-list {
      width: 100%;
      height: 1px;
      background-color: #d9d9d9;
      margin: 35px 0 40px 0;
    }
  }

  &-rounded {
    position: relative;
    padding-right: 10px;
    display: block;

    &::after {
      content:'';
      position: absolute;
      border-radius: 50%;
      background-color: $light-grey;
      width:2px;
      height: 2px;
      right: 4px;
      top: 8px;
    }
  }

  &-form {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 45px 0;
    &.margin-md-up-only {

      @include md {
        margin: 25px 0 45px 0;
      }
    }
  }

  &-docs {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 0 0 30px 0;
  }
}

