/**
*
* adds clearfix for floats
*
*/
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.clearfix {
  @include clearfix;
}

