.white-blue {
  .half-container {
    height: 100%;

    .homepage & {
      position: absolute;
      top: 0;
      right: 0;
      @include tablet {
        position: relative;
      }
    }
  }

  .txt-wrapper {
    text-align: center;
    position: relative;
    max-width: unset;
    height: 100%;
    padding: 40px 60px;

    .homepage & {
      padding: 60px 40px 40px 20px;
      max-width: 645px;
    }

    @include md {
      text-align: left;
    }

    @include lg {
      padding: 60px 30px;
    }

    @include xl {
      padding: 60px 77px;
    }
  }

  .left {
    margin-bottom: 5px;

    @include lg {
      margin-bottom: 0;
    }
  }

  .white {
    position: relative;
    background: url(../images/assets/bg-repeater-grey.svg) repeat-x;

    .title-block {
      margin-bottom: 30px;

      @include lg {
        flex-wrap: nowrap;
      }
    }

    .icon {
      display: block;
      margin: 0 auto 10px;

      @include md {
        margin: 0 30px 10px 0;
      }

      @include lg {
        margin: 0 48px 0 0;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .txt-block {
      width: 100%;

      @include lg {
        width: auto;
        margin-bottom: 30px;
      }
    }

    .lbl {
      font-weight: 500;
      letter-spacing: 0.8px;
      font-size: 2rem;
      display: block;
      max-width: 280px;
      margin: 0 auto 30px auto;

      @include md {
        width: 100%;
        margin: 0 0 25px 0;
      }

      @include lg {
        width: auto;
        max-width: none;
        margin: 0 0 5px 0;
      }
    }
    .nmbr {
      font-size: 4rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 1.6px;

      @include lg {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }
    }

    .note {
      font-size: 1.3rem;
      font-weight: 400;
      display: block;
      width: 100%;
      letter-spacing: initial;

      @include lg {
        display: initial;
        margin-left: 10px;
        width: auto;
      }
    }

    .bottom-txt {
      font-size: 1.3rem;

      .homepage & {
        position: initial;
      }

      @include lg {
        position: absolute;
        bottom: 20px;
        left: 30px;
        right: 30px;
      }

      @include small-laptop {
        bottom: 30px;
      }

      @include xl {
        left: 77px;
        right: 77px;
      }
    }
  }

  .blue {
    background-color: $brand-blue;
    color: white;

    @include lg {
      .txt-wrapper {
        padding: 60px 25px;
      }
    }

    .ic-virtual {
      &::before {
        display: block;
        margin: 0 auto 10px;

        @include md {
          margin: 0;
        }

        @include lg {
          margin: 0 15px 0 0;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .title {
      font-size: 3rem;
      display: block;
      margin-bottom: 30px;
    }

    .txt {
      margin-bottom: 32px;
    }
    .link {
      font-size: 1.4rem;
    }
  }
}
