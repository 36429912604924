body {
  overflow-x: hidden;
  background-color: $body-bg-color;
  font-size: $font-size-base;
}

.site {
  width: 100%;
}

.page-section {
  margin-bottom: 100px;
  @include tablet {
    margin-bottom: 80px;
  }
  @include mobile {
    margin-bottom: 50px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  font-weight: 500;
  margin: 0;
}
h1 {
  font-size: $txt-xxxl;
  max-width: 704px;
  font-weight: 500;
  letter-spacing: 0.88px;
  line-height: 6.5rem;

  &:before {
    content: "";
    display: block;
    margin-bottom: 15px;
    width: 72px;
    height: 6px;
    background-color: $brand-blue;
  }

  @include tablet {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  @include small-mobile {
    font-size: 3.2rem;
  }
}
h2 {
  font-size: $txt-xxl;
}
h3 {
  font-size: $txt-xl;
}
h4 {
  font-size: $txt-lg;
}
h5 {
  font-size: $txt-sm;
}
h6 {
  font-size: $txt-xs;
}

hr {
  border: none;
}

.btn {
  border: 0;
  border-radius: 26px;
  background-color: $brand-blue;
  text-transform: uppercase;
  font-size: 1.3rem;
  padding: 13px 25px;
  font-weight: 500;
  color: white;
  display: inline-block;
  letter-spacing: 0.87px;
  cursor: pointer;
  &.yellow {
    background: $brand-yellow;
  }
  &.grey {
    background-color: $text-color-light;
    font-size: 1.2rem;
    padding: 8px 25px;
  }
  &:hover {
    opacity: 0.9;
  }
}

.mark {
  &-featured {
    background-color: $brand-yellow;
    white-space: nowrap;
    color: #ffffff;
    font-size: $txt-xs;
    line-height: 40px;
    padding: 0 22px;
    position: absolute;
    top: 0;
  }
}

// helpers

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.full-height {
  height: 100%;
}

.desk-only {
  @include phablet {
    display: none;
  }
}

.tablet-only {
  display: none;

  @include tablet {
    display: block;
  }
}

.phablet-only {
  display: none;

  @include phablet {
    display: block;
  }
}

.margin-bottom-xs {
  margin-bottom: $margin-xs;
}

.v-center {
  align-items: center;
}

cite {
  font-style: normal;
}

.overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: black;
  opacity: 0;
  z-index: $zindex-lg;
  display: none;
  &.open {
    display: block;
    opacity: 0.5;
  }
}

a, input, button, select, textarea {
  outline: none;
}

.no-margin {
  margin: 0!important;
}

.no-margin-top {
  margin-top: 0!important;
}