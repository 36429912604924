// ----------------------------------------
// Colors
$brand-yellow: #ffc300;
$brand-blue: #3840e6;
$text-color: #000;
$text-color-light: #4d4d4d;
$light-grey: #797979;

$body-bg-color: #FFFFFF;


// ----------------------------------------
// Fonts
$font-family-base: 'Poppins', sans-serif!default;
$line-height-base: 1.5;

$txt-xxxl:6rem;
$txt-xxl:4rem;
$txt-xl:3rem;
$txt-lg:2rem;
$font-size-base: 1.6rem !default;
$txt-md: 1.6rem;
$txt-sm:1.4rem;
$txt-xs:1.3rem;
$txt-footer: 1.2rem;
$txt-xxs: 1.1rem;


// ---------------------------------------
// MARGINS
$margin-md: 30px;
$margin-xs: 15px;


// ---------------------------------------
// Z-INDEX FOR FIX ELEMENTS
$zindex-xl: 40 !default;
$zindex-lg: 20 !default;
$zindex-sm: 10 !default;
$zindex-xs: 1 !default;

// ----------------------------------------
// Animations
$animate-links: all 0.2s ease-in-out;