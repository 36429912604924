.video-area {
  .txt-wrapper {
    border: 1px solid #d9d9d9;
    border-right: 0;
    padding: 75px 75px 0;
    font-size: 0;
    display: flex;
    @include tablet {
      padding: 50px 50px 0;
      border-right: 1px solid #d9d9d9;
    }

    @include mobile {
      display: block;
      padding: 25px 25px 0;
    }
  }

  .ilustration {
    width: 175px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10%;
    @include mobile {
      display: block;
      width: 100%;
      margin-top: 20px;

      img {
        height: 150px;
      }
    }
  }
  .caption {
    width: calc(90% - 175px);
    display: inline-block;
    @include mobile {
      display: block;
      width: 100%;
    }
    .title {
      font-size: 4rem;
      letter-spacing: 1.6px;
      display: block;
      margin-bottom: 40px;
    }
    .txt {
      font-size: 1.6rem;
      margin-bottom: 35px;
    }
  }

  .bg-yellow {
    background: $brand-yellow;
    padding: 75px;
    @include mobile {
        padding:50px 40px;
      }
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    top: calc(50%);
    transform: translateY(-50%);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .bottom-info {
    color: white;
    position: absolute;
    bottom: 20px;
    font-size: 1.3rem;
    font-weight: 400;
    left: 40px;
    &::before {
      content: url(../images/assets/ic-youtube-white.svg);
      width: 21px;
      height: 15px;
    }
  }
}
