.main-banner {
  position: relative;
  max-height: 800px;
  

  .slide {
    &::after {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: $zindex-sm;
    }
  }

  .content {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    max-width: 1290px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    z-index: $zindex-lg;
  }

  .banner-img {
    object-fit: cover;
    width: 100%;
    height: 720px;

    @include mobile {
      height: 570px;
    }
  }

  .caption {
    position: absolute;
    bottom: 14%;
    left: 25px;
    z-index: $zindex-sm;
    color: white;
    width: 50%;
    z-index: 12;
    @include tablet {
      width: 80%;
    }
    @include mobile {
      width: calc(100% - 30px);
    }
  }

  .title{
    font-size: 6rem;
    letter-spacing: 1.5px;
    font-weight: 500;
    display: block;
    line-height: 1.1;
    margin-bottom: 100px;
    &::before{
      display: none;  
    }
    @include mobile {
      font-size: 3.5rem;
      letter-spacing: 0.88px;
      line-height: 1.2;
      margin-bottom: 50px;
    }
  }
  .pre-title {
    font-size: 1.6rem;
    font-weight: 400;
  }
}


