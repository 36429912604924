.breadcrumbs {
  color: $light-grey;
  font-size: $txt-xxs;
  font-weight: 400;
  line-height: 20px;
  margin: 20px 0 55px 0;
  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      pointer-events: none;
      text-decoration: underline;
    }
  }
}