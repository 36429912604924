$breakpoints: (smallmobile: 576px,
               mobile: 768px,
               phablet: 992px, 
               tablet: 1024px,
               laptop: 1280px,
               desktop: 1600px) !default;

/* ===================================================
*
* MIXINS DESKTOP FIRST
*
* ==================================================== */
@mixin desktop {
 @media only screen and (max-width: 1600px) {
   @content;
 }
}

@mixin laptop {
 @media only screen and (max-width: 1279px) {
   @content;
 }
}

@mixin tablet {
 @media only screen and (max-width: 1024px) {
   @content;
 }
}

@mixin phablet {
 @media only screen and (max-width: 991px) {
   @content;
 }
}

@mixin mobile {
 @media only screen and (max-width: 767px) {
   @content;
 }
}

@mixin small-mobile {
 @media only screen and (max-width: 575px) {
   @content;
 }
}

/* ===================================================
*
* MIXINS MOBILE FIRST
*
* ==================================================== */
@mixin xs {
  @media only screen and (min-width: 576px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin small-laptop {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}


/**
*
* Para quando temos media queries mais complexas 
*
* nota: usa as variáveis definidas na linha 1.
*
* ex: @include media(">=mobile", "<tablet") {}
*     == @media (min-width: 768px) and (max-width: 1023px){}
*
*/

/// @author Eduardo Boucas
$unit-intervals: ('px': 1) !default;

@mixin media($conditions...) {
  @for $i from 1 through length($conditions) {
    $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
  }

  $branches: get-query-branches($conditions);
  $query: '';

  @each $branch in $branches {
    @if (str-length($query) != 0) {
      $query: $query + ', ';
    }

    $query: $query + $branch;
  }

  @media #{$query} {
    @content;
  }
}

///
/// Reads a list of media query expressions and separates logical disjunctions into different branches
///
/// @param {List}   $expressions  - list of expressions
///
/// @throws `$expression` is not a valid expression
///
/// @return {List | Null}
///
@function get-query-branches($expressions) {
  $result: '';
  $has-groups: false;

  // Getting initial snapshot and looking for groups
  @each $expression in $expressions {
    @if (str-length($result) != 0) {
      $result: $result + ' and ';
    }

    @if (type-of($expression) == 'string') {
      $result: $result + $expression;
    } @else if (type-of($expression) == 'list') {
      $result: $result + nth($expression, 1);
      $has-groups: true;
    } @else {//se quiserem mudem a mensagem para pt.
      @warn '#{$expression} is not a valid expression.';
    }
  }

  @return $result;
}

///
/// Parses a string to form a media query expression
///
/// @author Eduardo Boucas
///
/// @param {String}   $expression  - expression (in string)
///
/// @throws Expression with type `type-of($expression)` detected, string expected
/// @throws `$expression` is missing an operator
/// @throws Unknown unit: `$unit`
///
/// @return {String | Null}
///
@function parse-expression($expression) {
  $operator: '';
  $value: '';
  $element: '';
  $result: '';
  $is-width: true;

  @if (type-of($expression) != 'string') {//se quiserem mudem a mensagem para pt.
    @warn 'Expression with type `#{type-of($expression)}` detected, string expected.';
  }

  // Separating the operator from the rest of the expression
  @if (str-slice($expression, 2, 2) == '=') {
    $operator: str-slice($expression, 1, 2);
    $value: str-slice($expression, 3);
  } @else {
    $operator: str-slice($expression, 1, 1);
    $value: str-slice($expression, 2);
  }

  // Checking what type of expression we're dealing with
  @if map-has-key($breakpoints, $value) {
    $result: map-get($breakpoints, $value);
  } @else if map-has-key($media-expressions, $expression) {
    $result: map-get($media-expressions, $expression);
    $is-width: false;
  } @else {
    $result: to-number($value);
  }

  @if ($is-width) {
    $unit: unit($result);
    $interval: 0;
    
    @if (map-has-key($unit-intervals, $unit)) {
      $interval: map-get($unit-intervals, $unit);
    } @else {
      @warn 'Unknown unit: #{$unit}';
    } 
       
    @if ($operator == '>') {
      $element: '(min-width: #{$result + $interval})';
    } @else if ($operator == '<') {
      $element: '(max-width: #{$result - $interval})';
    } @else if ($operator == '>=') {
      $element: '(min-width: #{$result})';
    } @else if ($operator == '<=') {
      $element: '(max-width: #{$result})';
    } @else {
      @warn '#{$expression} is missing an operator.';
    }
  } @else {
    $element: $result;
  }

  @return $element;
}

///
/// Replaces the first occurence of the string with the replacement string
///
/// @author Eduardo Boucas
///
/// @param {String}   $search  - The value being searched for
/// @param {String}   $replace  - The replacement string
/// @param {String}   $subject  - The string being replaced on
///
/// @return {String | Null}
///
@function str-replace-first($search, $replace, $subject) {
  $search-start: str-index($subject, $search);

  @if $search-start == null {
    @return $subject;
  }

  $result: str-slice($subject, 0, $search-start - 1);
  $result: $result + $replace;
  $result: $result + str-slice($subject, $search-start + str-length($search));

  @return $result;
}