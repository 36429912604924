[class*="ic-"] {
  &::before {
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.ic-virtual {
  &::before {
    content: "";
    background-image: url("../images/assets/ic-virtual.svg");
    width: 52px;
    height: 52px;
  }
}

.ic-call {
  &::before {
    content: "";
    background-image: url("../images/assets/ic-call.svg");
    width: 63px;
    height: 63px;

    @include lg {
      width: 87px;
      height: 87px;
    }
  }
}

.ic-news {
  &::before {
    content: "";
    background-image: url("../images/assets/ic-news.svg");
    width: 60px;
    height: 60px;
  }
}

.ic-events {
  &::before {
    content: "";
    background-image: url("../images/assets/ic-events.svg");
    width: 60px;
    height: 60px;
  }
}
