.links-area {
  .title {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.8px;
    display: inline-block;
    margin-bottom: 30px;
  }
  .btn {
      margin:0 20px 5px 0;
      &:last-child {
          margin-right: 0;
      }
  }
}


.link {
  transition: $animate-links;
  position: relative;
  font-size: 1.4rem;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    width: 0px;
    height: 1px;
    margin: 3px 0 0;
    transition: $animate-links;
    transition-duration: 0.75s;
    opacity: 0;
    background-color: currentColor;
  }

  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      width: 50%;
      opacity: 1;
    }
  }
}

.link-underline {
  transition: $animate-links;
  position: relative;
  font-size: 1.4rem;
  
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 1px;
    width: 51%;
    height: 1px;
    margin: 3px 0 0;
    transition: $animate-links;
    transition-duration: 0.2s;
    opacity: 1;
    background-color: currentColor;
  }
  
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      width: 0;
      opacity: 0;
    }
  }
}
