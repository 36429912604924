.img-txt-area{
    .txt-area {
        @include tablet {
            margin-bottom: 20px;
            text-align: center;
        }
    }
    .title {
        font-size: 6rem;
        margin-bottom: 20px;
        letter-spacing: 1.5px;
        line-height: 65px;
        &::before {
            display: none;
        }
    }
    .txt {
        margin-bottom: 40px;
        font-weight: 400;
    }
}