.cards-wrapper {
  &.w-all-border {
    @include mobile {
      .card-col {
        margin-bottom: 80px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .card {
      border: 1px solid #d9d9d9;
      padding: 75px 40px 50px;
      height: 100%;
      position: relative;
      &::before {
        content: "";
        width: 86px;
        height: 86px;
        top: -43px;
        left: 20px;
        position: absolute;
        z-index: 10;
      }

      .btn {
        margin-bottom: 5px;
      }
      &.blue {
        &::before {
          background: url(../images/assets/ic-operator.svg) no-repeat;
        }
      }
      &.yellow {
        &::before {
          background: url(../images/assets/ic-consumer.svg) no-repeat;
        }
      }
      .btn {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .pre-title {
      font-size: 1.3rem;
      font-weight: 400;
      display: block;
      margin-bottom: -5px;
      line-height: 1;
    }

    .title {
      font-size: 3rem;
      font-weight: 600;
      letter-spacing: 1.2px;
      margin-bottom: 40px;
    }
    .bottom {
      margin-top: 50px;
      font-size: 0;
    }
  }
}
